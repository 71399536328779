@import "src/assets/themes/user-variables";

.loginWrap{
  min-height: 100vh;
}
.banner{
  background-color: $white;
  color: $white;
  background-image: none;
  background-repeat: no-repeat;
  background-position: 0 11%;
  background-size: 100%;
}
.loginRight{
  position: relative;
}

.welcomeTitle{
  &:global(.MuiTypography-root){
    font-size: 1.625rem;
  }
}

.takingTestWrap{
  max-width: 440px;
  padding-bottom: 65px;
  .logoWrapper{
    margin-bottom: 30px !important; 
    img{
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.userLoginHeading{
  h2{
    font-weight: $font-weight-semi-bold;
    font-family: $poppins;
    letter-spacing: -0.02em;
  }
  p{
    font-family: $MulishMedium;
    color: $gray-500;
  }
}
.poweredByWrap{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: none;
  background: white;
  padding: 1.25rem 0;
  img{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.timerCount{
  font-family: $MulishSemiBold;
  text-align: right;
  color: $gray-600;
}
.loginBannerMob{
  width: 100%;
}
@media (min-width: 767px) {
  .banner{
    background-image: url("../../assets/images/candidate-login-bgV2.png");
    background-size: cover;
  }
  .loginBannerMob{
    display: none;
  }
  .poweredByWrap{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 767px) {
  .takingTestWrap{
    background-color: $white;
    padding: 2.5rem 1.25rem;
    box-shadow: 0 .0625rem 0.125rem rgba($gray-700, 0.15);
    border-radius: 0.75rem;
    width: 100%;
    max-width: initial;
    button{
      width: 100%;
    }
  }
  .loginRight{
    margin-bottom: 80px;
    margin-top: -150px;
  }
  .userLoginHeading{
    h2{
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
    p{
      font-family: $MulishMedium;
      color: $gray-500;
    }
  }
}