.notification-banner {
  position: absolute;
  left: 0;
  right: 0;
  z-index:2100;
  &.warning {
    background-color: $proctor;
  }
  // &.notify-static {
  //   position: static;
  // }
}
