.token{
  color: $snow-11;
}
.keyword{
  color: $blue-14;
}
.number{
  color: $warning;
}
.infinity{
  color: $green-12;
}
.pre{
  word-wrap: break-word;
  white-space: pre-wrap;
}