.card{
  border-color: #E4E6EF;
  &.rounded-6{
    border-radius: .375rem;
  }
}
.card-dashed{
  &.MuiCard-root{
    border: .0625rem dashed $gray-15;
    border-radius: .375rem;
    background-color: rgba($gray-14, .4);
  }
}

.card-md-top{
  padding: 1rem;
  background-color: $white;
}

.card-snow{
  &.MuiCard-root{
    background-color: $snow-13;
    box-shadow: 0 0 toRem(30) rgba(56, 71, 109, 0.09);
  }
}

@media (min-width: 768px) {
  .card-{
    &lg{
      > .MuiCardContent-root{
        padding: toRem(50) toRem(100);
        &:last-child{
          padding-bottom: toRem(50);
        }
      }
    }
    &lg2{
      > .MuiCardContent-root{
        padding: toRem(40) toRem(70);
        &:last-child{
          padding-bottom: toRem(40);
        }
      }
    }
    &md{
      > .MuiCardContent-root{
        padding: toRem(30) toRem(50);
        &:last-child{
          padding-bottom: toRem(40);
        }
      }
    }
  }
  .card-md-top{
    padding: toRem(30) toRem(50);
  }
}

