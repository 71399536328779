.modal-box{
  @extend %absoluteCenter;
  max-width: 43.75rem;
  width: 100%;
  padding: 1rem;
  border: none;
  box-shadow: none;
  outline: none;
}
.modal-content{
  background-color: $white;
  border-radius: .75rem;
}

.drawer{
  width: 100%;
  max-width: 800px;
  height: 100%;
  &.drawer-sm{
    max-width: 600px;
  }
  &.right{
    @extend %absoluteTopRight;
  }
  .modal-content{
    border-radius: 0;
    height: 100%;
    transform: translateX(100%);
    transition: transform 0.3s ease-out .3s;

  }
  &.open{
    .modal-content{
      transform: translateX(0);
    }
  }
}