// color
$black: #000;
$white: #fff;
$dark: #212121;
$secondary: #b5b5c3;
$success: #29cc97;
$danger: #f64e60;
$primary: #7a1af0;
$warning: #ffac2f;
$proctor: #fee4e4;

$black-10: #3f4254;
$black-11: #5e6278;
$black-12: #181c32;
$black-13: #313042;
$black-14: #9099a3;
$black-15: #434254;
$black-16: #1f1e2e;
$black-17: #80808f;
$black-18: #333b68;
$black-19: #252c51;

$green-11: #1bc5bd;
$green-12: #10b759;
$green-13: #0bb783;
$green-14: #d7f9ef;

$blue-11: #8950fc;
$blue-12: #009ef6;
$blue-13: #eee5ff;
$blue-14: #3699ff;
$blue-15: #403e5d;
$blue-16: #f1faff;
$blue-17: #006cba;

$snow-11: #e7ecf0;
$snow-12: #f3f6f9;
$snow-13: #f9fafb;
$snow-14: #c2cbd1;

$orange-10: #fff7e7;

$gray-11: #b5b5c3;
$gray-12: #e4e6ef;
$gray-13: #7e8299;
$gray-14: #f5f8fa;
$gray-15: #d8dfe3;
$gray-16: #e5eaee;
$gray-17: #dcdeec;
$gray-18: #d9d9d9;
$gray-19: #eff2f5;

$yellow-11: #ffa800;
$yellow-12: #fff4de;
$yellow-13: #ffe9be;

$red-11: #ffe2e5;

//New Variables
$purple-50: #fbeeff;
$purple-100: #f8e6ff;
$purple-150: #dfd3fc;
$purple-200: #f1ccff;
$purple-300: #d359fe;
$purple-400: #be50e5;
$purple-500: #a947cb;
$purple-600: #9e43bf;
$purple-700: #7f3598;
$purple-800: #5f2872;
$purple-900: #4a1f59;

$gray-50: #f2f2f2;
$gray-100: #ececec;
$gray-200: #d7d7d7;
$gray-300: #7e7e7e;
$gray-400: #adadad;
$gray-500: #717171;
$gray-600: #656565;
$gray-700: #5f5f5f;
$gray-750: #2c2c2c;
$gray-800: #4c4c4c;
$gray-900: #393939;

$violet-200: #7b27f0;

// font family
$poppins: "Poppins";
$roboto: "Roboto";
$MulishRegular: "MulishRegular";
$MulishMedium: "MulishMedium";
$MulishSemiBold: "MulishSemiBold";
$font-family-base: $roboto;
$font-family-roboto: $roboto;
$font-family-poppins: $poppins;

//font-size
$font-size-xs: 0.75rem;
$font-size-xmd: 0.812rem;
$font-size-sm: 0.875rem;
$font-size-base: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.25rem;
$font-size-xxl: 1.5rem;

//font-weight
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;

$path-to-fonts: "../../fonts" !default;
