.badge{
  font-size: 0.8125rem;
  font-weight: $font-weight-semi-bold;
  font-family: $MulishRegular;
  border-radius: .375rem;
  padding: .5rem .625rem;
  line-height: 1rem;
  &.badge-soft-warning{
    color: $yellow-11;
    background-color: $yellow-12;
  }
  &.badge-soft-danger{
    color: $danger;
    background-color: $red-11;
  }
  &.badge-soft-success{
    color: $green-13;
    background-color: $green-14;
  }
  &.badge-soft-primary{
    color: $purple-500;
    background-color: $purple-100;
  }
  &.badge-snow{
    background-color: rgba($primary, .1);
    color: $black-10;
  }
}
.badge-strip{
  min-height: 2rem;
  padding: 3px 24px 3px 12px;
  position: relative;
  display: inline-block;
  z-index: 1;
  &.primary{
    background-color: $primary;
    color: $white;
  }
  &::after{
    position: absolute;
    content: '';
    left: calc(100% - 11px);
    z-index: 1;
    width: 25px;
    height: 23px;
    top: 5px;
    background-color: $white;
    transform: rotate(45deg);
  }
}
.trapezium{
  position: absolute;
  height: calc(100% + 10px);
  width: .375rem;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: $blue-17;
  clip-path: polygon(0 12%, 100% 0, 100% 100%, 0 88%);
  z-index: 1;
}
.strip-border{
  position: absolute;
  left: .375rem;
  top: 50%;
  transform: translateY(-50%);
  width: .0625rem;
  height: calc(100% + 16px);
  background-color: $gray-11;
}
.award{
  position: relative;
  &-right{
    .badge-strip{
      padding: 3px 12px 3px 24px;
      &::after{
        right: calc(100% - 11px);
        left: unset;
      }
    }
    .trapezium{
      right: 0;
      left: unset;
      clip-path: polygon(0 0, 100% 12%, 100% 88%, 0 100%);
    }
    .strip-border{
      right: .375rem;
      left: unset;
    }
  }
  &.secondary{
    .trapezium{
      background-color: $snow-14;
    }
    .strip-border{
      border-color:  $snow-14;
    }
    .secondary{
      color: $black-12;
      background: linear-gradient(270.07deg, #E6EBEF 0.07%, #F5F8FA 121.6%);
    }
  }
}
