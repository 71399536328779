.asset{
  background-color: $snow-12;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: .375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.img-asset{
  min-width: 150px;
  width: 150px;
  height: 100px;
  overflow: hidden;
  border-radius: .375rem;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &.img-asset-sm{
    min-width: 90px;
    width: 90px;
    height: 60px;
  }
  &.img-asset-xs{
    min-width: 36px;
    width: 60px;
    height: 36px;
  }
}