%d-flex{
  display: flex;
}

%flex-column{
  flex-direction: column;
}

%justify-content-between{
  justify-content: space-between;
}

%justify-content-end{
  justify-content: flex-end;
}

%align-items-center{
  align-items: center;
}

%flex-wrap{
  flex-wrap: wrap;
}

%absoluteTopLeft{
  position: absolute;
  top: 0;
  left: 0;
}
%absoluteTopRight{
  position: absolute;
  top: 0;
  right: 0;
}


%absoluteVCenter{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

%absoluteCenter{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%absoluteBottom{
  position: absolute;
  bottom: 0;
  left: 0;
}