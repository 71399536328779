@font-face {
  font-family: 'icomoon';
  src:  url('#{$path-to-fonts}/Icomoon/icomoon.eot?62fj0h');
  src:  url('#{$path-to-fonts}/Icomoon/icomoon.eot?62fj0h#iefix') format('embedded-opentype'),
  url('#{$path-to-fonts}/Icomoon/icomoon.ttf?62fj0h') format('truetype'),
  url('#{$path-to-fonts}/Icomoon/icomoon.woff?62fj0h') format('woff'),
  url('#{$path-to-fonts}/Icomoon/icomoon.svg?62fj0h#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-solid-up:before {
  content: "\e904";
}
.icon-play:before {
  content: "\e903";
}
.icon-right-pentagon:before {
  content: "\e902";
}
.icon-star:before {
  content: "\e900";
}
.icon-stopwatch:before {
  content: "\e901";
}
