@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,800;1,300;1,400&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,700&display=swap');
@import "assets/themes/theme.scss";



// this needs to be placed correctly
.company-logo {
    object-fit: contain;
    max-width: 196px;
    height: 50px;
}