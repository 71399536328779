.video-record{
  width: 100%;
  // height: 475px;
  height: 290px;
  border-radius: .375rem;
  position: relative;
  overflow: hidden;
  background-color: $snow-12;
  .video{
    width: 100%;
    height: 100%;
    transform: scale(-1,1);
  }
  .record-control-bar{
    @extend %absoluteBottom;
    background-color: rgba($black, .5);
    width: 100%;
    min-height: 50px;
  }
  .stream-time{
    @extend %absoluteVCenter;
    right: 1.875rem;
  }
  .record-label{
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding-left: 1.25rem;
    color: $white;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.35);
    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: .75rem;
      height: .75rem;
      border-radius: 50%;
      background: #FF3030;
      box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
    }
  }
}