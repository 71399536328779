
@font-face {
  font-family: 'MulishRegular';
  src: url('../../fonts/Mulish/Mulish-Regular.woff2') format('woff2'),
  url('../../fonts/Mulish/Mulish-Regular.woff') format('woff'),
  url('../../fonts/Mulish/Mulish-Regular.otf') format('otf'),
  url('../../fonts/Mulish/Mulish-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MulishMedium';
  src: url('../../fonts/Mulish/Mulish-Medium.woff2') format('woff2'),
  url('../../fonts/Mulish/Mulish-Medium.woff') format('woff'),
  url('../../fonts/Mulish/Mulish-Medium.otf') format('otf'),
  url('../../fonts/Mulish/Mulish-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'MulishSemiBold';
  src: url('../../fonts/Mulish/Mulish-SemiBold.woff2') format('woff2'),
  url('../../fonts/Mulish/Mulish-SemiBold.woff') format('woff'),
  url('../../fonts/Mulish/Mulish-SemiBold.otf') format('otf'),
  url('../../fonts/Mulish/Mulish-SemiBold.ttf') format('truetype');
}
