.coding-box{
  background-color: $black-12;
  color: $white;
}

.testOutputPanel{
  position: relative;
  background-color: $black-19;
  .outputTop{
    padding: .375rem 1.25rem;
    background-color: $black-18;
  }
  .outputBody{
    max-height: 15rem;
    overflow: auto;
  }
}
.codeWrap{
  min-height: 15rem;
  overflow: auto;
}

.token{
  color: $snow-11;
}
.keyword{
  color: $blue-14;
}
.number{
  color: $warning;
}
.infinity{
  color: $green-12;
}
.pre{
  word-wrap: break-word;
  white-space: pre-wrap;
}