.circular-progress{
  position: relative;
  .progress-track{
    &.MuiCircularProgress-root{
      color:#E9E9FF;
    }
  }
  .progress-bar{
    position: absolute;
    left: 0;
    top: 0;
  }
  .progress-label-box{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}