.error {
  color: $danger;
  font-size: 13px;
  line-height: 21px;
  margin-top: 6px;
}

.form-control {
  width: 100%;
  background: $white;
  border: 0.0625rem solid $gray-12;
  height: 2.5rem;
  border-radius: 0.375rem;
  padding: 0.375rem 1rem;
  font-size: $font-size-sm;
  color: $black-11;
  font-family: $MulishRegular;
  &:not([readonly]):focus {
    border: 0.0625rem solid $purple-500;
  }
  @include placeholder($gray-11, 500);
  &.form-control-sm {
    height: 2.5rem;
  }
  &.form-control-lg {
    height: 3.125rem;
  }
  &.form-control-md {
    height: 2.75rem;
  }
  &.form-control-sm {
    height: 2.125rem;
    font-size: 0.75rem;
  }
  &.text-area {
    height: toRem(300);
  }
  &.text-area-sm {
    height: toRem(130);
  }
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: $font-size-sm;
  font-family: $MulishMedium;
  color: $gray-800;
}

.hidden-input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
}

.upload-box {
  position: relative;
  border: 0.06255rem solid $gray-12;
  padding: 0.5rem;
}

.toggle-btn-group {
  .MuiToggleButton-root {
    border-radius: 50px;
    min-width: 7.5rem;
    border-color: $gray-12;
  }
  .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type) {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    margin: 0;
    border-left-color: $gray-12;
  }
}

.number-icon-hidden::-webkit-outer-spin-button,
.number-icon-hidden::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

//custom checkbox and radio
%custom-control-input {
  z-index: 1;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
}
%custom-control-label-before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  pointer-events: none;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: block;
  border: 0.0625rem solid #adadad;
  background-color: #f5f8fa;
  border-radius: 0.375rem;
}

%custom-control-label {
  padding-left: 2rem;
  line-height: 1.125rem;
  color: $gray-600;
  font-family: $MulishRegular;
  a {
    font-family: $MulishMedium;
    color: $purple-500;
  }
}

.custom-input-test {
  z-index: 1;
  width: 24px;
  min-width: 24px;
  height: 24px;
  outline: none;
  // accent-color: $purple-500;
}

.custom-label-test {
  line-height: 1.25rem;
  color: $gray-750;
  font-family: "Inter", "sans-serif";
  a {
    font-family: "Inter", "sans-serif";
    color: $purple-500;
  }
}

.form-type-wrapper {
  font-family: $MulishRegular;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  padding: 4px 6px;
  background-color: #f2ebff;
  border-radius: 4px 0px;
}

.custom-checkbox {
  padding: 0;
  position: relative;
  .custom-control-input {
    @extend %custom-control-input;
    + .custom-control-label {
      @extend %custom-control-label;
      &:before {
        @extend %custom-control-label-before;
        border-radius: 0.375rem;
      }
      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 4px;
        width: 7px;
        height: 14px;
        border-style: solid;
        border-width: 0 0.125rem 0.125rem 0;
        border-color: #fff;
        transform: rotate(45deg);
        border-radius: 0;
      }
    }
    &:not(:disabled) {
      &:active {
        ~ .custom-control-label {
          &:before {
            border-color: $purple-500;
            background-color: $gray-14;
          }
        }
      }
    }
    &:checked {
      ~ .custom-control-label {
        color: $black;
        &:before {
          border-color: $purple-500;
          background-color: $purple-500;
        }
        &:after {
          background-image: none;
          display: block;
        }
      }
    }
    &:focus {
      ~ .custom-control-label {
        &:before {
          box-shadow: none;
        }
      }
      &:not(:checked) {
        ~ .custom-control-label {
          & ~ .custom-control-label {
            & ~ .custom-control-label::before {
              border-color: rgba($blue-11, 0.25);
            }
          }
        }
      }
    }
    &[disabled],
    &:disabled {
      ~ .custom-control-label {
        color: rgba($black, 0.4);
        &:before {
          border-color: rgba($black, 0.4);
          background-color: $white;
        }
      }
    }
  }
}

.custom-radio {
  padding: 0;
  position: relative;
  .custom-control-input {
    @extend %custom-control-input;
    + .custom-control-label {
      @extend %custom-control-label;
      &:before {
        @extend %custom-control-label-before;
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        pointer-events: none;
        cursor: pointer;
        width: 25px;
        height: 25px;
        display: block;
        border: 0.0625rem solid #adadad;
        background-color: #f5f8fa;
        border-radius: 50%;
      }
      &:after {
        content: "";
        position: absolute;
        background-color: $blue-11;
        display: none;
        left: 5px;
        top: 5px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
    }
    &:not(:disabled) {
      &:active {
        ~ .custom-control-label {
          &:before {
            border-color: rgba($blue-11, 0.25);
            background-color: $white;
          }
        }
      }
    }
    &:checked {
      ~ .custom-control-label {
        color: $black-10;
        &:before {
          border-color: rgba($blue-11, 0.25);
          background-color: $gray-14;
        }
        &:after {
          background-image: none;
          display: block;
        }
      }
    }
    &:focus {
      ~ .custom-control-label {
        &:before {
          box-shadow: none;
        }
      }
      &:not(:checked) {
        ~ .custom-control-label {
          & ~ .custom-control-label {
            & ~ .custom-control-label::before {
              border-color: rgba($blue-11, 0.25);
            }
          }
        }
      }
    }
    &[disabled],
    &:disabled {
      ~ .custom-control-label {
        color: rgba($black-11, 0.4);
        &:before {
          border-color: rgba($blue-11, 0.25);
          background-color: $white;
        }
      }
    }
  }
}

.switch-btn {
  .MuiSwitch-sizeMedium {
    width: 42px;
    height: 26px;
    padding: 0;
  }
  .MuiFormControlLabel-label {
    font-weight: $font-weight-medium;
  }
}

.search-with-icon {
  position: relative;
  .search-icon {
    @extend %absoluteVCenter;
    left: 0.875rem;
  }
  .form-control {
    padding-left: 2rem;
  }
}

.upload-circle {
  width: 5.875rem;
  height: 5.875rem;
  border: 0.0625rem solid rgba($gray-17, 0.6);
  border-radius: 50%;
  padding: 1.25rem;
  position: relative;
  &::before {
    content: "";
    @extend %absoluteCenter;
    width: calc(100% - 1.25rem);
    height: calc(100% - 1.25rem);
    border: 0.1875rem solid rgba($gray-13, 0.5);
    border-radius: 50%;
  }
  :global(.icon-arrow-solid-up) {
    color: $blue-11;
    font-size: 1.5rem;
  }
}

.custom-list-item {
  &:hover {
    color: #ffff;

    .custom-label-test {
      color: #ffff;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
