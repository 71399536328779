@mixin placeholder($color, $font-weight){
  &::-webkit-input-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
  &:-ms-input-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
}

@function toRem($value){
  $remValue: ($value / 16 ) + rem;
  @return $remValue;
}