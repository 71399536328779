.tabs-wrap{
  .MuiTabs-flexContainer{
    justify-content: flex-start;
  }
  .MuiTab-root{
    text-transform: capitalize;
    color: $black-10;
    font-family: $font-family-roboto;
    font-size: 1rem;
    &.Mui-selected{
      color: $blue-14;
    }
  }
  &.code-tab{
    .MuiTabPanel-root{
      background-color: $black-12;
      color: $snow-11;
      padding: 1.25rem;
    }
    .pills-tab{
      .MuiTabPanel-root{
        padding: 1.25rem 0;
      }
    }
  }
  &.pills-tab{
    .MuiTabs-indicator{
      display: none;
    }
    .MuiTabs-root{
      min-height: auto;
    }
    .MuiTab-root{
      color: $gray-11;
      border-radius: .375rem;
      min-height: 34px;
      padding: 7px 16px;
      line-height: 1;
      &.Mui-selected{
        color: $blue-14;
        background-color: $blue-15;
      }
    }
    &.tabs-primary{
      .MuiTab-root{
        &.Mui-selected{
          background-color: $blue-14;
          color: $white;
        }
      }
    }
  }
  &.question-tabs{
    .MuiTabPanel-root{
      padding: .625rem 0 0;
    }
  }
  &.v-tabs{
    .MuiTab-root{
      align-items: flex-start;
      font-family: $font-family-base;
      font-size: .875rem;
      font-weight: $font-weight-medium;
      line-height: 1.25rem;
      border-radius: .25rem;
      padding: .75rem 0.9375rem;
      white-space: nowrap;
    }
    .MuiTabPanel-root{
      padding: 0 0 0 1.875rem;
    }
  }
}

.tabs-v-left{
  border-right: .0625rem solid $gray-19;
}