.accordion-wrap{
  &.MuiAccordion-root{
    box-shadow: none;
    border: .0625rem solid $gray-15;
  }
  .MuiAccordionSummary-root{
    padding: .625rem 1rem;
    min-height: unset;
    &.Mui-expanded{
      min-height: unset;
      .MuiAccordionSummary-content{
        margin: 0;
      }
    }
  }
  .MuiAccordionSummary-content{
    margin: 0;
    justify-content: space-between;
    align-items: center;
  }
  .MuiAccordionDetails-root{
    background-color: $gray-14;
  }
}