.chip-purple{
  &.MuiButtonBase-root{
    color: $blue-11;
    background-color: $blue-13;
    &:hover{
      background-color: darken($blue-13,4%);
      .MuiChip-deleteIcon{
        color: darken($blue-11,4%);
      }
    }
    .MuiChip-deleteIcon{
      color: $blue-11;
    }
  }
}