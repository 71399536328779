.btn {
  font-family: $font-family-base;
  &.btn-link {
    min-width: auto;
    color: $blue-12;
  }
  border-radius: 0.375rem;
}

button:disabled,
button[disabled] {
  border: 1px solid rgba($dark, 0.4);
  background-color: $white;
  color: $secondary;
  cursor: default;
}

.btn-light {
  background-color: $white;
  color: $secondary;
  &.btn-toggle {
    .toggle-icon {
      color: $secondary;
    }
  }
}

.btn-snow {
  background-color: $snow-12;
  color: $gray-13;
  &:hover,
  &.btn:first-child:hover {
    background-color: $snow-12 !important;
  }
}

.btn-dark {
  &.MuiButton-outlinedSecondary {
    border-color: $dark;
    color: $dark;
  }
}

.btn-light-primary {
  background: $blue-16;
  color: $blue-12;
  border: 0.0625rem solid $blue-12;
  &:hover,
  :active {
    background: $blue-16 !important;
    border: 0.0625rem solid $blue-12 !important;
    color: $blue-12 !important;
  }
}

.btn-lg {
  height: 3.125rem;
  padding: 0.75rem 1.5rem;
}

.btn-violet {
  position: relative;
  overflow: hidden;

  &:hover,
  :active {
    .arrow-icon {
      opacity: 1;
      animation: slide-in 1s forwards;
    }
  }
}

.button-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-icon {
  opacity: 0;
  margin-left: -30px; /* Start position off to the left */
  transition: opacity 1s, margin-left 1s;
}

@keyframes slide-in {
  from {
    margin-left: -30px;
  }
  to {
    margin-left: 10px;
  }
}

.btn-purple {
  font-family: $MulishMedium;
  background: $purple-500;
  font-size: $font-size-sm;
  color: $white;
  line-height: 1.5rem;
  border: 0.0625rem solid $purple-500;
  &:hover,
  :active {
    background: $purple-600 !important;
    border: 0.0625rem solid $purple-600 !important;
    color: $white !important;
  }
}

.btn-xl {
  height: 2.75rem;
  padding: 0.75rem 2rem;
}
